.ac_select-with-icon {
  &__wrapper {
    position: relative;
    width: 100%;
  }
  &__icon {
    fill: #353535;
    position: absolute;
    left: 12px;
    top: 33px;
    pointer-events: none;
    width: 24px;
    height: 24px;
  }
}