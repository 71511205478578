@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.bar-box {
    background: cssvar(bg-primary-color);
    border: 1px solid cssvar(grayscale-30-color);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 13px 13px;
    margin-bottom: 6px;

    &--with-hover {
        &:hover {
            background: #e5f9c0;

            // to fix segments background overrides
            .results-col {
                &--dep {
                    table {
                        tbody {
                            tr {
                                &:nth-child(2),
                                &:nth-child(3) {
                                    background: #e5f9c0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--animate {
        animation: blink 0.8s;
    }
}

.result-item {
    display: flex;
    align-items: center;

    &__utc {
        font-size: 13px;
        color: cssvar(grayscale-70-color)
    }

    .results-col--line {
        padding-left: 20px;
    }

    .results-col__stop {
        margin-top: 0;
        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

    &__notif-type {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-name {
            min-width: 130px;
        }
    }

    &__action-button {
        width: 36px;
        height: 36px;
        border: 1px solid cssvar(grayscale-50-color);
        border-radius: 6px;
        background-color: white;
        cursor: pointer;

        svg {
            margin: 1px 0 0 -1px;
            fill: cssvar(grayscale-50-color);
        }
    }
    &__order-link {
        display: inline-block;
        margin-right: cssvar(spacing-2);
    }
}

.ride-info-tag {
    color: cssvar(primary-content-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    padding: 3px 12px;
    margin: 0;
    background-color: cssvar(bg-primary-color);
    border: 1px solid cssvar(grayscale-50-color);
    box-sizing: border-box;
    border-radius: 30px;
    display: inline-block;

    &--small {
        font-size: 14px;
        padding: 2px 6px;
        line-height: 18px;
        max-width: 118px;
        text-align: center;
    }

    &--warning {
        color: cssvar(warning-color);
        border-color: cssvar(warning-color);
    }
    &--success {
        color: cssvar(success-color);
        border-color: cssvar(success-color);
    }

    &__line-indicator {
        display: flex;
        align-items: center;
        color: cssvar(primary-content-color);
        text-decoration: none;
    }

    &__icon {
        width: 17px;
        height: 17px;
        margin-right: 4px;
    }
}

.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.autocontrol-results-header {
    transition: all 0.5s linear;

    &--with-sticky-header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 100;
        max-width: 1318px;
        margin: 0 auto;
        padding-top: 67px;
        background: cssvar(grayscale-10-color);
        border-bottom: 1px solid cssvar(grayscale-30-color);
    }
}

@keyframes blink {
    0% {opacity: 1}
    50% {opacity: 0.1}
    100% {opacity: 1}
    
}
