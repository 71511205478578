.scroll-to-top {
  color: black;
  position: fixed;
  bottom: 70px;
  right: 70px;
  text-align: center;
  font-size: 10px;
  cursor: pointer;

  &__button {
    height: 50px;
    width: 50px;
    z-index: 9;
    outline: none;
    border-radius: 50%;

    &:focus {
      outline: none;
    }
  }

  &__text {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}