.automations-page {
  display: flex;

  &__filters {
    margin-right: 24px;
    min-width: 296px;
    position: sticky;
    top: 0;
    align-self: flex-start;

    &-row {
      margin: 12px 0 0 0;
    }
  }
  &__table {
    width: 100%;
    padding: 0 0 24px 0;
  }
}

.hcr-pager {
  margin: 24px 0 0;

  li:first-of-type,
  li:last-of-type {
    overflow: hidden;
  }
}
