.settings-page {
    display: flex;

    &__filters {
        margin-right: 24px;
        min-width: 296px;
        max-width: 350px;
        position: sticky;
        top: 0;
        align-self: flex-start; // fixes sticky with display flex

        &-row {
            margin: 12px 0 0 0;
        }
    }
    &__table {
        width: 100%;
        padding: 0 0 24px 0;
    }

    #delays {
        background: #fff;
    }
}

.hcr-pager {
    margin: 24px 0 0;

    li:first-of-type,
    li:last-of-type {
        overflow: hidden;
    }
}

.change-line-setting {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 95px;
    padding: 0 0 0 10px;
    line-height: 30px;
    max-height: 30px;

    & > label {
        width: 100%;
    }

    div[class*='switch'] {
        width: 100%;
        padding: 0;

        &:before,
        &:after {
            top: 50%;
        }
    }
}
