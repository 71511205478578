@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools';

.ac {
  &_archived-results {
    &__controls-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-right: cssvar(spacing-6);
      padding-left: cssvar(spacing-6);
    }
    &__date-input {
      width: 250px;
    }
    &__calendar-container {
      position: relative;
    }
    &__calendar {
      position: absolute;
      top: 0;
      left: 0;
    }
    &__spinner {
      margin-top: 100px;
      text-align: center;
    }
    &__pagination {
      text-align: center;
    }
  }
}