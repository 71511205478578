@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools';

body {
    margin: 0;
    background-color: cssvar(bg-secondary-color);
}

.autocontrol {
    &-content-block {
        position: relative;
        width: 1318px;
        max-width: 100%;
        margin: 0 auto;
        padding: cssvar(spacing-4) 2%;

        &--wide {
            width: 100%;
        }
        &--section {
            background-color: cssvar(bg-primary-color);
        }
    }
    &__filters-wrapper {
        position: relative;
    }
    &__filters-pane {
        padding: 0 cssvar(spacing-4);
        background: #ffffff;
        max-height: 0;
        transition: all 0.15s ease-out;
        z-index: 100;

        &--active {
            padding: cssvar(spacing-4) 0 0 0;
            max-height: 500px;

            .autocontrol__filters-pane-wrapper {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__filters-pane-wrapper {
        opacity: 0;
        visibility: hidden;
        transition: all 0.15s ease-out;
    }
    &__filters-status {
        ul {
            width: 100%;
            li {
                width: 33.333333%;
            }
        }
    }
    &__filters-cta {
        position: absolute;
        right: 32px;
        top: 0;

        @media (min-width: 1200px) {
            right: cssvar(spacing-4);
            top: 0;
        }

        &:enabled:hover {
            background: none;
        }
    }
    &__context-block {
        max-width: 1318px;
        margin: 0 auto;
        transition: padding 0.25s ease;
    }
    &__sub-header {
        position: relative;

        #timezone-switcher {
            position: absolute;
        }
    }
    &-main-wrapper {
        background: cssvar(grayscale-10-color);
    }
    &-filters {
        padding: cssvar(spacing-4);
        border: 1px solid cssvar(line-primary-color);
        background-color: cssvar(bg-primary-color);
    }
    &-results-info {
        padding: cssvar(spacing-4) cssvar(spacing-4) 0;
    }
    &-results-list {
        padding: cssvar(spacing-4);
        background: cssvar(grayscale-10-color);
        min-height: calc(
            100vh - 320px
        ); // 60px header height and 260px filters height
    }
    &-results-header {
        display: flex;
        padding: 23px 6px 18px;
    }
    &-cell--alignment-right {
        text-align: right;
    }
    &__picker {
        [class*='autocomplete__dropdown'] {
            top: 100%;
        }
    }
}

.ride-search-suggestions-option {
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-normal);
    line-height: cssvar(line-height-small);
    color: cssvar(primary-content-color);
    margin: 0;
    &__title {
        display: block;
        font-weight: cssvar(font-weight-semibold);
    }
}

.rs-combo-picker {
    position: relative;
    display: flex;

    &__left,
    &__right {
        display: inline-block;
    }
    &__left {
        width: 51%;

        input {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: cssvar(spacing-4);

            &:focus {
                border-right: none;
            }
        }
    }
    &__right {
        width: 49%;

        input {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
                border-left: none;
            }
        }
    }
    &__switch {
        position: absolute;
        top: 50%;
        left: 51%;
        height: 32px;
        width: 32px;
        margin-top: -4px;
        text-align: center;
        line-height: 30px;
        border: 1px solid cssvar(line-primary-color);
        border-radius: 100%;
        background-color: cssvar(bg-primary-color);
        transform: translate(-50%) rotate(0deg);
        cursor: pointer;
        transition: transform 0.4s;

        &--rotate {
            transform: translate(-50%) rotate(180deg);
        }
    }
}

.results-col {
    &--line {
        width: 11%;
        padding-left: 50px;
    }
    &--dep {
        width: 45%;
        padding-right: 1%;

        .results-col__title {
            padding-left: 15px;
        }

        table {
            margin-bottom: 0;

            tbody {
                tr {
                    position: relative;

                    [class*='connection__station-info'] {
                        position: absolute;
                        font-size: 13px;
                        background: none;
                        left: 12px;
                        top: 2px;
                        letter-spacing: -0.5px;

                        &:after {
                            display: none;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        padding: 0 cssvar(spacing-half);
                        background: cssvar(grayscale-10-color);

                        div {
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
    }
    &--created {
        width: 11%;
    }
    &--type {
        width: 33%;
        padding-right: 1%;
    }
    &--status {
        width: 8%;
    }
    &--actions {
        width: 4%;
    }

    &__title {
        font-size: 16px;
        font-weight: 700;
        line-height: cssvar(spacing-4);
        letter-spacing: 0;
        text-align: left;
    }
    &--sorting {
        cursor: pointer;
    }
}

.ago {
    display: block;
}

.utc {
    font-size: 13px;
}

#timezone-switcher {
    padding: 0 cssvar(spacing-6);
}
